
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import ChaKanTouSu from "./TouSu.vue";
import { nextTick } from 'vue';
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver';
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigTouSu",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: { ChaKanTouSu },
  setup(props, { emit }) {
    const datePickerVisible = ref(false);
    let data = reactive<any>({
      init: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      url: "",
      maps: new Map(),
      data: {},
      option: {},
      title: "",
      total: 0, // 总条目数
      test:"1231412412",
      exportList: [],
      complaintFormLogSettlementList:[],
      iOperate: 0,
      selectform: {
        SpName:"",        
        SpValue: 0,
        PageIndex: 1,
        PageSize: 10,
      },
      viewLogsForm:{
        ComplaintFormId: ""
      },
      tousuDialogVisible: false,
      DateSelect: [],
      isSelect: 1,
      options: [
        { label: '适老服务', value: '19' },
        { label: '健康助手', value: '4' },
        { label: '车主权益', value: '15' },
      ],
      
      shortcuts: [
    {
        text: '今天',
        onClick:(picker: any)  =>  {
            // console.log("picker");
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
        }
    },
    {
        text: '昨天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近7天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近15天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近30天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // console.log(data.DateSelect);
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近3月',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
]
    });

    
    
    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getComplaintFormLogSettlementList();
    };
    
    const clearDateSelect = () => {      
      // let startstr = ""
      // let endstr = ""
      data.DateSelect = []
      // data.DateSelect.push(endstr)
      // console.log(data.DateSelect[0]);
    };
    const clearChangeSpName = () => {      
      data.selectform.SpName = ""
      data.selectform.SpValue = 0
      data.isSelect = 0;
      // console.log(data.isSelect);
    };
    const selectSpName = async (item: any) => {
      await nextTick();
      // console.log(item);
        // console.log(data.isSelect);
      if(data.isSelect > 0){
        data.selectform.SpValue = parseInt(item.value);
        data.selectform.SpName = item.label;
        // console.log(item);
      }
        data.isSelect = 1;
        
    };
    const handleSizeChange = (val: any) => {
      data.selectform.PageSize = val;
      getComplaintFormLogSettlementList();  
    };
    const handleCurrentChange = (val: any) => {
      data.selectform.PageIndex = val;
      getComplaintFormLogSettlementList();  
    };
    const selectTouSu = (item: any) => {      
    // let add = {Phone: "13399075993", PackagesId: 20, ContactPhone: "13399075993", Memo: ""}
    //     Api.Config.addTouSu(add).then((res: any) => {
        
    //     console.log(res);
    //     if (res.Code == 0) {  
    //         Api.Msg.success("投诉单已成功添加");
    //         getComplaintForm();
    //     } 
    //   });
    // console.log(data.exportList);
      getComplaintFormLogSettlementList();
    };
    const getComplaintFormLogSettlementList = () => {
      if(data.DateSelect[0] == ""){
        data.DateSelect[0] = '';
        data.DateSelect[1] = '';
      }
      if(data.DateSelect == null){
        data.DateSelect[0] = '';
        data.DateSelect[1] = '';
      }
      if(data.DateSelect[0] === undefined){
        data.DateSelect[0] = '';
        data.DateSelect[1] = '';
      }
      data.complaintFormLogSettlementList = [];
      let tousudata = {StartDate: data.DateSelect[0], EndDate: data.DateSelect[1],SpId: data.selectform.SpValue,PageIndex: data.selectform.PageIndex, PageSize: data.selectform.PageSize}
      console.log(tousudata);
      Api.Config.GetComplaintFormLogSettlementList(tousudata).then((res: any) => {
        // console.log("res");
        console.log(res);
        
        if (res.Code == 0) {  
            data.total = res.Data.total;
            for(var i = 0; i < res.Data.data.length; i++){
                res.Data.data[i].CreatedAt = res.Data.data[i].CreatedAt.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
                switch (res.Data.data[i].Operate) {
                    case 0:
                        res.Data.data[i][i].Operate = "新增投诉单";
                        res.Data.data[i].iOperate = 0
                    break;
                    case 1:
                        res.Data.data[i].Operate = "修改信息";
                        res.Data.data[i].iOperate = 1
                    break;
                    case 2:
                        res.Data.data[i].Operate = "退订业务";
                        res.Data.data[i].iOperate = 2
                    break;
                    case 3:
                        res.Data.data[i].Operate = "回访";
                        res.Data.data[i].iOperate = 3
                    break;
                    case 4:
                        res.Data.data[i].Operate = "充值退费";
                        res.Data.data[i].Operate2 = "退费金额：" + (res.Data.data[i].Information / 100) + "元";
                        res.Data.data[i].iOperate = 4
                    break;
                    case 5:
                        res.Data.data[i].Operate = "当月退费";
                        res.Data.data[i].Operate2 = "退费金额：" + (res.Data.data[i].Information / 100) + "元";
                        res.Data.data[i].iOperate = 5
                    break;
                    case 6:
                        res.Data.data[i].Operate = "上传退费凭证";
                        res.Data.data[i].iOperate = 6
                    break;
                    case 7:
                        res.Data.data[i].Operate = "完成投诉单";
                        res.Data.data[i].iOperate = 7
                    break;
                    case 8:
                        res.Data.data[i].Operate = "直接退费";
                        res.Data.data[i].Operate2 = "退费金额：" + (res.Data.data[i].Information / 100) + "元";
                        res.Data.data[i].iOperate = 8
                    break;
                }
                data.complaintFormLogSettlementList.push(res.Data.data[i]);
                // list.push(res.Data.data[i])
            }
            
            // console.log("2");
            
        } 
      });
            console.log(data.complaintFormLogSettlementList);
    };

    const viewRefundVoucher = (row: any) => {
      let url = "https://a.xjjkzs.com/" + row.Information;
      // console.log("row");
      console.log(row);
      data.url = url;
      data.dialogFormVisible3 = true;
    }

    const exportTouSu = (exportList: any[]) => {
      
      
      // 获取表格数据
      const touSuData = exportList;
      // console.log("2");
      // console.log(data.complaintFormList);
      console.log(exportList);
      
     
      var touSuList = [['用户号码','套餐名称','操作人','操作时间','操作','退费金额','附加信息']];
      
      var photoUrl = '';
      for(let i = 0; i < touSuData.length; i++){
        // console.log(touSuData[i].RefundVoucher);
        switch (touSuData[i].Operate) {
            case 0:
                touSuData[i].Operate = "新增投诉单";
            break;
            case 1:
                touSuData[i].Operate = "修改信息";
            break;
            case 2:
                touSuData[i].Operate = "退订业务";
            break;
            case 3:
                touSuData[i].Operate = "回访";
            break;
            case 4:
                touSuData[i].Operate = "充值退费";
                touSuData[i].Operate2 = "退费金额：" + (touSuData[i].Information / 100) + "元";
                touSuData[i].money = touSuData[i].Information / 100;
            break;
            case 5:
                touSuData[i].Operate = "当月退费";
                touSuData[i].Operate2 = "退费金额：" + (touSuData[i].Information / 100) + "元";
                touSuData[i].money = touSuData[i].Information / 100;
            break;
            case 6:
                touSuData[i].Operate = "上传退费凭证";
                if(touSuData[i].Information != null && touSuData[i].Information != 'null' && touSuData[i].Information != ''){
                    touSuData[i].Operate2 = 'https://a.xjjkzs.com/' + touSuData[i].Information;
                }
            break;
            case 7:
                touSuData[i].Operate = "完成投诉单";
            break;
            case 8:
                touSuData[i].Operate = "直接退费";
                touSuData[i].Operate2 = "退费金额：" + (touSuData[i].Information / 100) + "元";
                touSuData[i].money = touSuData[i].Information / 100;
            break;
        }
        
        
        var touSuListData = [touSuData[i].Phone,touSuData[i].PackagesName,touSuData[i].Operator,touSuData[i].CreatedAt,touSuData[i].Operate,touSuData[i].money,touSuData[i].Operate2];
        touSuList.push(touSuListData)
        touSuData[i].Operate2 = '';
      }
      // console.log(touSuList);
      // // 定义表头
      // // 将数据转换为工作表
      // const worksheet = XLSX.utils.json_to_sheet(touSuList, { header: headers });
      const worksheet = XLSX.utils.aoa_to_sheet(touSuList);
      // // 创建一个新的工作簿
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      // // 生成Excel文件并触发下载
      const xlsxOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      FileSaver.saveAs(new Blob([xlsxOut], { type: 'application/octet-stream' }), "投诉单结算.xlsx");
    };

    const clickExportTouSu = () => {
      if(data.DateSelect[0] == ""){
        ElMessageBox.alert("请选择日期");
        return;
      }
      // if(data.DateSelect == null){
      //   ElMessageBox.alert("请选择开始日期");
      //   return;
      // }
      if(data.DateSelect[0] === undefined){
        ElMessageBox.alert("请选择日期");
        return;
      }
      getExport();
    }
    const getExport = () => {
      if(data.DateSelect[0] == ""){
        data.DateSelect[0] = '';
        data.DateSelect[1] = '';
      }
      if(data.DateSelect == null){
        data.DateSelect[0] = '';
        data.DateSelect[1] = '';
      }
      if(data.DateSelect[0] === undefined){
        data.DateSelect[0] = '';
        data.DateSelect[1] = '';
      }
      data.exportList = [];
      let tousudata = {StartDate: data.DateSelect[0], EndDate: data.DateSelect[1],SpId: data.selectform.SpValue,PageIndex: 1, PageSize: 99999}
      console.log(tousudata);
      Api.Config.GetComplaintFormLogSettlementList(tousudata).then((res: any) => {
        // console.log("res");
        // console.log(res);
        
        if (res.Code == 0) {  
            // data.total = res.Data.total;
            data.total = res.Data.total;
            for(var i = 0; i < res.Data.data.length; i++){
                res.Data.data[i].CreatedAt = res.Data.data[i].CreatedAt.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ');
                // switch (res.Data.data[i].Operate) {
                //     case 0:
                //         res.Data.data[i][i].Operate = "新增投诉单";
                //         res.Data.data[i].iOperate = 0
                //     break;
                //     case 1:
                //         res.Data.data[i].Operate = "修改信息";
                //         res.Data.data[i].iOperate = 1
                //     break;
                //     case 2:
                //         res.Data.data[i].Operate = "退订业务";
                //         res.Data.data[i].iOperate = 2
                //     break;
                //     case 3:
                //         res.Data.data[i].Operate = "回访";
                //         res.Data.data[i].iOperate = 3
                //     break;
                //     case 4:
                //         res.Data.data[i].Operate = "充值退费";
                //         res.Data.data[i].Operate2 = "退费金额：" + (res.Data.data[i].Information / 100) + "元";
                //         res.Data.data[i].iOperate = 4
                //     break;
                //     case 5:
                //         res.Data.data[i].Operate = "当月退费";
                //         res.Data.data[i].Operate2 = "退费金额：" + (res.Data.data[i].Information / 100) + "元";
                //         res.Data.data[i].iOperate = 5
                //     break;
                //     case 6:
                //         res.Data.data[i].Operate = "上传退费凭证";
                //         res.Data.data[i].iOperate = 6
                //     break;
                //     case 7:
                //         res.Data.data[i].Operate = "完成投诉单";
                //         res.Data.data[i].iOperate = 7
                //     break;
                //     case 8:
                //         res.Data.data[i].Operate = "直接退费";
                //         res.Data.data[i].Operate2 = "退费金额：" + (res.Data.data[i].Information / 100) + "元";
                //         res.Data.data[i].iOperate = 8
                //     break;
                // }
                data.exportList.push(res.Data.data[i]);
            } 
            exportTouSu(data.exportList);
        
        }
      });
    };
    onMounted(() => {
        getComplaintFormLogSettlementList();
    });

    return {
      ...toRefs(data),
      getComplaintFormLogSettlementList,
      handleSizeChange,
      handleCurrentChange,
      changePage,
      selectTouSu,
      viewRefundVoucher,
      selectSpName,
      clearChangeSpName,
      clearDateSelect,
      clickExportTouSu,
      exportTouSu
    };
  },
});
